<template>
  <div class="support-container">
    <SectionTitle title="Liên hệ" link="/" />
    <div class="support">
      <div
        v-for="(item, index) in contactsMB"
        :id="`support-${item?.name}`"
        :key="item.name"
        class="support__item"
        :class="[item?.type, item?.className]"
      >
        <div>
          <NuxtLink :to="item?.url" target="_blank" class="support__item--link">
            <CommonBaseImg v-if="!isBotChromeLighthouse" class="icon" :src="listIcon[index]" :alt="item.name" />
            <p class="text">{{ item.name }}</p>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAppStore } from '~/store/app'
import { getFooterContact } from '@/constants/footer'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
const { isBotChromeLighthouse } = useAppStore()
const { SUPPORT_MENU } = getFooterContact()
const { fetchBranchMBContact, contactsMB } = useContact()

const listIcon = [
  '/assets/images/components/desktop/footer/icon-livechat-mb.svg',
  '/assets/images/components/desktop/footer/icon-email.svg',
  '/assets/images/components/desktop/footer/icon-telegram-mb.svg'
]
onMounted(async () => {
  await fetchBranchMBContact()
})
</script>
<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/section-contact/index.scss" />
